import { Controller } from '@hotwired/stimulus'
import DevLog from './shared/DevLog'
import { RecaptchaLoader, RecaptchaExecute } from './loaders/RecaptchaLoader'

// This adds an event listener for form submit to insert form data from
// reCAPTCHA and then submit the form. Because we're overriding turbo behavior
// here to prevent the form from being submitted 2x, we will also here disable
// the submit button.
// We can't currently use `turbo:submit-start` event, seeing 2x submit.
// https://turbo.hotwired.dev/handbook/drive#form-submissions
export default class extends Controller {
  connect () {
    DevLog(['reCAPTCHA preparing'])

    const form = this.element
    const { recaptchaSiteKey } = form.dataset

    this.recaptchaPromise = RecaptchaLoader(recaptchaSiteKey)
    this.recaptchaPromise.then((grecaptcha) => {
      grecaptcha.ready(() => {
        DevLog(['reCAPTCHA ready'])
        form.addEventListener('submit', (e) => this.handleFormSubmit(e, form))
      })
    })
  }

  handleFormSubmit (e, form) {
    e.preventDefault()

    const { recaptchaAction, recaptchaSiteKey } = form.dataset
    const formField = document.getElementById('g-recaptcha-response-data')

    // Disable submit button ourselves since we aren't allowing turbo here.
    //   Ref: https://github.com/hotwired/turbo/pull/386/files
    e.submitter.setAttribute('disabled', '')

    DevLog(['reCAPTCHA execute', recaptchaAction])

    RecaptchaExecute(
      this.recaptchaPromise,
      recaptchaAction,
      recaptchaSiteKey,
      form,
      formField
    )
  }
}
