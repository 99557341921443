import { Loader } from '@googlemaps/js-api-loader'

// Singleton pattern with Promise to ensure only called once.
let googleMapsPromise

export const GoogleMapsLoader = (apiKey) => {
  if (!googleMapsPromise) {
    const loader = new Loader({ apiKey, version: 'quarterly' })
    googleMapsPromise = loader.load()
  }
  return googleMapsPromise
}

// https://developers.google.com/maps/documentation/javascript/overview#js_api_loader_package
// position = { lat: ..., lng: ... }
export const DrawMap = ({ mapElement, position, label }) => {
  const map = new window.google.maps.Map(mapElement, {
    center: position,
    zoom: 10
  })
  const info = new window.google.maps.InfoWindow({ content: label })
  const marker = new window.google.maps.Marker({ position, map, title: label })

  marker.addListener('click', () => {
    info.open(map, marker)
  })
}
