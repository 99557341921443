import { Controller } from '@hotwired/stimulus'
import DevLog from './shared/DevLog'
import {
  StripeLoaderWithAccount,
  RedirectToCheckout
} from './loaders/StripeLoader'

export default class extends Controller {
  static values = {
    publicKey: String,
    accountId: String,
    sessionId: String
  }

  connect () {
    DevLog(['Preparing for Stripe Checkout Registration'])

    this.stripePromise = StripeLoaderWithAccount(
      this.publicKeyValue,
      this.accountIdValue
    )

    // 500 delay for redirect to payment
    setTimeout(
      () => RedirectToCheckout(this.stripePromise, this.sessionIdValue),
      500
    )
  }
}
