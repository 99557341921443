import DynamicScriptLoader from './DynamicScriptLoader'

// Singleton pattern with Promise to ensure only called once.
let googleAnalyticsPromise

const GoogleAnalyticsLoader = () => {
  if (!googleAnalyticsPromise) {
    googleAnalyticsPromise = DynamicScriptLoader({
      url: 'https://www.google-analytics.com/analytics.js',
      method: 'ga',
      name: 'Google Analytics'
    })
  }
  return googleAnalyticsPromise
}

export default GoogleAnalyticsLoader
