import DynamicScriptLoader from './DynamicScriptLoader'

// Singleton pattern with Promise to ensure only called once.
let googleChartsPromise

const GoogleChartsLoader = () => {
  if (!googleChartsPromise) {
    googleChartsPromise = DynamicScriptLoader({
      url: 'https://www.gstatic.com/charts/loader.js',
      method: 'google',
      name: 'Google Charts'
    })
  }
  return googleChartsPromise
}

export default GoogleChartsLoader
