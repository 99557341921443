import { Controller } from '@hotwired/stimulus'
import DevLog from './shared/DevLog'
import { StripeLoader, RedirectToCheckout } from './loaders/StripeLoader'

export default class extends Controller {
  static values = {
    publicKey: String,
    sessionId: String
  }

  connect () {
    DevLog(['Preparing for Stripe Checkout'])

    this.stripePromise = StripeLoader(this.publicKeyValue)
    RedirectToCheckout(this.stripePromise, this.sessionIdValue)
  }
}
