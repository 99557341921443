import DynamicScriptLoader from './DynamicScriptLoader'

let recaptchaPromise

// Using `cacheScript = false` to make sure the script is fetched every time
// this is called, ie on each page load, so we always have a fresh reCAPTCHA.
export const RecaptchaLoader = (recaptchaSiteKey) => {
  recaptchaPromise = DynamicScriptLoader({
    url: `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`,
    method: 'grecaptcha',
    name: 'reCAPTCHA v3',
    cacheScript: false
  })

  return recaptchaPromise
}

export const RecaptchaExecute = (
  recaptchaPromise,
  recaptchaAction,
  recaptchaSiteKey,
  form,
  formField
) => {
  recaptchaPromise.then((grecaptcha) => {
    grecaptcha
      .execute(recaptchaSiteKey, { action: recaptchaAction })
      .then((token) => {
        formField.value = token
        form.submit()
      })
  })
}
