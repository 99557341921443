import { loadStripe } from '@stripe/stripe-js/pure'

// Singleton pattern with Promise to ensure only called once.
let stripePromise

export const StripeLoader = (stripePublicKey) => {
  if (!stripePromise) {
    stripePromise = loadStripe(stripePublicKey)
  }
  return stripePromise
}

export const StripeLoaderWithAccount = (stripePublicKey, stripeAccount) => {
  let promise

  if (!promise) {
    promise = loadStripe(stripePublicKey, { stripeAccount })
  }
  return promise
}

// Return the value of the promise using .then
// https://dev.to/ramonak/javascript-how-to-access-the-return-value-of-a-promise-object-1bck
export const RedirectToCheckout = (stripePromise, sessionId) => {
  stripePromise.then((stripe) => {
    stripe.redirectToCheckout({ sessionId })
  })
}
