import { Controller } from '@hotwired/stimulus'
import DevLog from './shared/DevLog'
import { GoogleMapsLoader, DrawMap } from './loaders/GoogleMapsLoader'

export default class extends Controller {
  static values = {
    apiKey: String,
    latitude: String,
    longitude: Number,
    label: String,
    zoom: Number
  }

  connect () {
    DevLog(['Google Maps loading'])

    GoogleMapsLoader(this.apiKeyValue).then(() => {
      const mapElement = this.element
      const lat = parseFloat(this.latitudeValue) || 0
      const lng = parseFloat(this.longitudeValue) || 0
      const position = { lat, lng }
      const label = this.labelValue || ''
      DevLog(['Google Maps drawing:', position, label])

      DrawMap({ mapElement, position, label })
    })
  }
}
