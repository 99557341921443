// For external scripts that set methods on `window` like window.google, etc
// Inspired by https://github.com/stripe/stripe-js/blob/34bec49bbd9d16c95a2e49e97d8ee8a8784f69ad/src/shared.ts#L68

import DevLog from '../shared/DevLog'

const scriptInjector = (url) => {
  const script = document.createElement('script')
  script.src = url
  document.head.appendChild(script)
  return script
}

const DynamicScriptLoader = ({ url, method, name, cacheScript = true }) => {
  return new Promise((resolve, reject) => {
    DevLog(['ScriptLoader:', name])

    if (cacheScript && window[method]) {
      DevLog(['ScriptLoader:', name, 'found'])
      resolve(window[method])
      return
    }

    try {
      const script = scriptInjector(url)

      script.addEventListener('load', () => {
        if (window[method]) {
          DevLog(['ScriptLoader:', name, 'loaded'])
          resolve(window[method])
        } else {
          reject(new Error(`${name} is not available`))
        }
      })

      script.addEventListener('error', () => {
        reject(new Error(`${name} failed to load`))
      })
    } catch (error) {
      reject(error)
    }
  })
}

export default DynamicScriptLoader
