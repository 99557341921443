// DEPRECATED
// Generic helper controller to toggle (add or remove) the "hidden" class,
// used for dropdowns and popovers.

import { Controller } from '@hotwired/stimulus'
import { ToggleAll, ToggleHide } from './shared/Toggle'

export default class extends Controller {
  static targets = ['target']

  toggle (e) {
    e.preventDefault()

    const targets = this.targetTargets
    ToggleAll({ targets })
  }

  // Needed for mobile "click" to allow it to disappear
  hide (e) {
    const el = this.element
    const target = this.targetTarget
    const clickOutsideTarget = el.contains(e.target) === false

    if (clickOutsideTarget) {
      ToggleHide({ target })
    }
  }
}
