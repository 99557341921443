import { Controller } from '@hotwired/stimulus'
import LazyObserver from './shared/LazyObserver'
import DevLog from './shared/DevLog'

// Lazy load elements with data-controller="lazy" directly attached to them.
export default class extends Controller {
  connect () {
    DevLog(['Lazy loader ready'])
    const observer = LazyObserver(this.element)
    observer.observe()
  }
}
