// Load Sentry early to report all errors
import * as Sentry from '@sentry/browser'
/* eslint-enable no-undef */

// Stimulus
import './controllers'

// Turbo
import '@hotwired/turbo-rails'

Sentry.init({
  environment: document.querySelector('meta[name="app:env"]').getAttribute('content'),
  release: document.querySelector('meta[name="app:revision"]').getAttribute('content'),
  dsn: 'https://a2dd90458f3c4ca2bb4118777178d99f@sentry.io/785453',
  ignoreErrors: ['NS_ERROR_NOT_INITIALIZED']
})

// Set global environment variables
// - APP_ENV is needed by DevLog.js
window.APP_ENV = document.querySelector('meta[name="app:env"]').getAttribute('content')

// require('@rails/activestorage').start()
// require('../channels')

// Add Content Security Policy nonce to Turbo requests
// https://discuss.rubyonrails.org/t/problems-integrating-hotwire-into-existing-rails-project/77990/13
document.addEventListener('turbo:before-fetch-request', function (event) {
  event.detail.fetchOptions.headers['X-Turbo-Nonce'] = document
    .querySelector("meta[name='csp-nonce']")
    .getAttribute('content')
})

// Preserve nonces in scripts and styles on Turbo cache
document.addEventListener('turbo:before-cache', function () {
  document
    .querySelectorAll('script[nonce]')
    .forEach((element) => element.setAttribute('nonce', element.nonce))
  document
    .querySelectorAll('style[nonce]')
    .forEach((element) => element.setAttribute('nonce', element.nonce))
})
