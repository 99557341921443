/* global localStorage, SecurityError */

import DevLog from './DevLog'

// Added after seeing Chrome Mobile throw "SecurityError. The operation is insecure." errors.
export const LocalStorageGetItem = (key) => {
  try {
    return localStorage.getItem(key)
  } catch (e) {
    if (e instanceof SecurityError) {
      DevLog([`SecurityError while getting ${key} from localStorage`, e])
      return null
    } else {
      throw e
    }
  }
}

export const LocalStorageSetItem = (key, value) => {
  try {
    localStorage.setItem(key, value)
  } catch (e) {
    if (e instanceof SecurityError) {
      DevLog([`SecurityError while setting ${key} in localStorage`, e])
    } else {
      throw e
    }
  }
}
