// Inspired by luxon (moment.js reborn) presets
// https://github.com/moment/luxon/blob/5c265a45f93fab77c5331caa3c369d290aadb251/src/impl/formats.js#L34
const PRESETS = {
  dateTimeMed: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  },
  dateMed: {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  },
  timeSimple: {
    hour: 'numeric',
    minute: 'numeric'
  }
}

const options = ({ preset = 'dateTimeMed', hideYear }) => {
  const presetOpts = PRESETS[preset]

  if (hideYear === 'true') {
    const { year, ...presetOptsWithoutYear } = presetOpts
    return presetOptsWithoutYear
  }

  return presetOpts
}

const showTimeOnly = ({ timeOnly }) => timeOnly === 'true'

// Get the browser's navigator language if available, falling back to 'en'.
// Ref: https://stackoverflow.com/a/52112155/967115
const navigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0]
  }

  return (
    navigator.userLanguage ||
    navigator.language ||
    navigator.browserLanguage ||
    'en-US'
  )
}

const timezoneOffset = (date) => date.getTimezoneOffset() * 60000

// Uses el.dateTime from <time datetime="..."></time>
// if data-utc="true" then convert the time back to UTC and display it as is,
// ie not local to the user's browser.
const dateTime = (el, utc = el.dataset.utc) => {
  const date = new Date(el.dateTime)

  if (utc === 'true') {
    return new Date(date.getTime() + timezoneOffset(date))
  }

  return date
}

// Check if the  locales and options arguments are supported by user's browser
const supportsLocalesAndOpts = () => {
  try {
    new Date().toLocaleDateString('i')
  } catch (e) {
    return e.name === 'RangeError'
  }
  return false
}

// Localize dates/times using JS and browser
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
// The toLocaleDateString() method returns a string with a language sensitive
// representation of the date portion of this date.
// Fallback to <time title="..."> if browser doesn't support locale and opts.
const LocaleDate = (el) => {
  if (!supportsLocalesAndOpts()) {
    return el.title
  }

  const date = dateTime(el)
  const locale = navigatorLanguage()

  if (showTimeOnly(el.dataset)) {
    const opts = PRESETS.timeSimple
    return date.toLocaleTimeString(locale, opts)
  }

  const opts = options(el.dataset)
  return date.toLocaleDateString(locale, opts)
}

export default LocaleDate
