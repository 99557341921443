// Webpack 5 dynamic code splitting using dynamic import.
// https://webpack.js.org/guides/code-splitting/

// Singleton pattern with Promise to ensure only called once.
let chartkickPromise

const ChartkickLoader = () => {
  if (!chartkickPromise) {
    // ChartJS supports dark mode, so using it
    chartkickPromise = import('chartkick/chart.js')
  }
  return chartkickPromise
}

export default ChartkickLoader
