import { Application } from '@hotwired/stimulus'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

export { application }

// From previous index.js:
// import { Application } from '@hotwired/stimulus'
// import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

// const application = Application.start()
// const context = require.context('controllers', true, /_controller\.js$/)
// application.load(definitionsFromContext(context))

// Enable Stimulus debug mode in development
// application.debug = process.env.NODE_ENV === 'development'
