/* global FormData */
//
// Auto-submit a form
// <%= form_with ... data: { controller: 'form' } do %>
//   <%= select_tag ... data: { action: 'form#autoSubmit' } %>
// <% end %>
//
// Auto-get a search form
// <%= form_with ... data: { controller: 'form' } do %>
//   <%= select_tag ... data: { action: 'form#autoGet' } %>
// <% end %>

import { Controller } from '@hotwired/stimulus'
import AlertMsg from './shared/AlertMsg'

export default class extends Controller {
  autoSubmit () {
    const form = this.element

    // Safari doesn't support .requestSubmit()
    if (form.requestSubmit) {
      form.requestSubmit()
    } else {
      form.submit()
    }
  }

  autoUpload (e) {
    const el = e.target
    el.classList.add('hidden')
    const msg = 'Uploading...'
    el.insertAdjacentHTML('afterend', AlertMsg({ msg, type: 'alert-info' }))
    this.autoSubmit()
  }

  // URL string from form fields: https://stackoverflow.com/a/57250225/967115
  // Add basic debounce (delay after user stops typing)
  // Ref: https://medium.com/swlh/instant-search-results-with-rails-6-and-stimulus-ffaf333926b5
  // TODO: Fix with autofocus disabled this removes cursor form search box.
  //      With it enabled it puts cursor at beginning of box. Both not ideal.
  autoGet (e) {
    e.preventDefault()
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const queryString = formQueryString(this.element)
      window.Turbo.visit(`${window.location.pathname}?${queryString}`)
    }, 500)
  }
}

const formQueryString = (form) => {
  const formData = new FormData(form)
  const searchParams = new URLSearchParams(formData)
  return searchParams.toString()
}
