import { Controller } from '@hotwired/stimulus'
import DevLog from './shared/DevLog'
import IntercomLoader from './loaders/IntercomLoader'
import * as Sentry from '@sentry/browser'

// Future notes
// If needed can hide and show the launcher by calling
// Show: window.Intercom("update", { hide_default_launcher: false });
// Hide: window.Intercom("update", { hide_default_launcher: true });
export default class extends Controller {
  static values = {
    appId: String,
    json: String
  }

  connect () {
    DevLog(['Intercom loading'])

    let jsonData
    try {
      jsonData = JSON.parse(this.jsonValue)
      DevLog(['Validated JSON', jsonData])
    } catch (error) {
      DevLog(['Invalid JSON format:', error])
      Sentry.captureMessage(`Invalid JSON format: ${error.message}`, 'error')
      return
    }

    IntercomLoader(this.appIdValue).then(() => {
      DevLog(['Intercom loaded', jsonData])
      window.Intercom('boot', jsonData)
    }).catch(error => {
      DevLog(['Error loading Intercom:', error])
      Sentry.captureMessage(`Error loading Intercom: ${error.message}`, 'error')
    })
  }
}
