// Use data-path=".." to redirect on select to that path for tabs navigation

import { Controller } from '@hotwired/stimulus'
import SelectedOption from './shared/SelectedOption'

export default class extends Controller {
  select (e) {
    const { path } = SelectedOption(e.target).dataset

    window.Turbo.visit(path)
  }
}
