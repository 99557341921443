import { Controller } from '@hotwired/stimulus'
import DevLog from './shared/DevLog'
import ChartkickLoader from './loaders/ChartkickLoader'

export default class extends Controller {
  initialize () {
    DevLog(['Chartkick loading'])
    ChartkickLoader()
  }
}
