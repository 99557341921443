/* global IntersectionObserver */

// Use IntersectionObserver to lazy load images, background images, or Stimulus
// controllers. Simplified from Lozad https://github.com/ApoorvSaxena/lozad.js
// If IntersectionObserver isn't supported, load things immediately.
// See comments below for usage/
const load = (element) => {
  // For images, <img> elements
  // Use: data-src="..."
  // To set: <img src="...">
  if (element.getAttribute('data-src')) {
    element.src = element.getAttribute('data-src')
  }

  // For background images, on <div>'s or other elements
  // Use: data-background-image="..."
  // To set: style="background-image: url('...')"
  if (element.getAttribute('data-background-image')) {
    element.style.backgroundImage = `url('${element.getAttribute(
      'data-background-image'
    )}')`
  }

  // For Stimulus JS controllers
  // Use: data-lazy-controller="..."
  // To set: data-controller="..."
  if (element.getAttribute('data-lazy-controller')) {
    element.dataset.controller = element.getAttribute('data-lazy-controller')
  }
}

const browserSupports = (type) => window && window[type]

const markAsLoaded = (element) => element.setAttribute('data-loaded', true)

const isLoaded = (element) => element.getAttribute('data-loaded') === 'true'

const onIntersection = (load) => (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.intersectionRatio > 0 || entry.isIntersecting) {
      observer.unobserve(entry.target)

      if (!isLoaded(entry.target)) {
        load(entry.target)
        markAsLoaded(entry.target)
      }
    }
  })
}

const LazyObserver = (element) => {
  let observer
  if (browserSupports('IntersectionObserver')) {
    observer = new IntersectionObserver(onIntersection(load))
  }

  return {
    observe () {
      if (isLoaded(element)) {
        return
      }

      if (observer) {
        observer.observe(element)
        return
      }

      // If observer is not supported by browser, fallback to immediately load
      load(element)
      markAsLoaded(element)
    }
  }
}

export default LazyObserver
