export const TOGGLE_CLASS = 'hidden'
const COLLAPSE_CLASS = 'show'

export const HasToggleClass = (el, toggleClass) =>
  el.classList.contains(toggleClass)

// Both Collapse and Toggle controllers use this to toggle their CSS
// classes (above). Collapse also updates aria-expanded for screen readers.
// Should we do the same for regular/menu toggle_controller?
export const ToggleAll = ({ targets, collapse = false }) => {
  const toggleClass = collapse ? COLLAPSE_CLASS : TOGGLE_CLASS

  Array.from(targets).forEach((target) => {
    const el = target
    el.classList.toggle(toggleClass)

    if (collapse) {
      el.setAttribute('aria-expanded', HasToggleClass(el, toggleClass))
    }
  })
}

export const ToggleHide = ({ target }) => {
  target.classList.add(TOGGLE_CLASS)
}

export const ToggleShow = ({ target }) => {
  target.classList.remove(TOGGLE_CLASS)
}
