// Instead of export default this exports named constants
// to allow other classes to call openEditor, close
import AppendParams from './AppendParams'

const editor = () => document.getElementById('editor')

const alertMsg = () => document.getElementById('alert')

const editorIsOpen = () => editor() !== null

// Checks if the editor is for the same item id and "view", edit or score
const editorAlreadyOpen = (el, view) =>
  editorIsOpen() &&
  editor().dataset.itemId === el.id &&
  editor().dataset.editorView === view

export const CloseEditor = (e) => {
  e.preventDefault()

  if (alertMsg() !== null) {
    alertMsg().remove()
  }

  if (editorIsOpen()) {
    editor().remove()
  }
}

// Uses a turbo-frame to fetch url
export const OpenEditor = (
  el,
  e,
  view,
  url = el.dataset.url,
  location = el.dataset.location
) => {
  e.preventDefault()

  // Check if editor is already open to return early below, ie to close it.
  const alreadyOpen = editorAlreadyOpen(el, view)

  // Close all open editors, so only 1 is open at a time
  CloseEditor(e)

  // If it was already open, just return. In effect this is a toggle to close.
  if (alreadyOpen) {
    return
  }

  const editorUrl = AppendParams(url, `v=${view}`)
  const insertLocation = location || 'beforeend'
  // Setting `target="_top" here allows all links inside the frame to navigate
  // the window. Any form inside this frame can add `data-turbo-frame="_self"`.
  const editorFrame = `
    <turbo-frame id="editor" src="${editorUrl}" data-editor-view="${view}"
                 data-item-id="${el.id}" target="_top">
      <div class="spinner animate-spin my-4"></div>
    </turbo-frame>
  `
  el.insertAdjacentHTML(insertLocation, editorFrame)
}

export const EditorItem = () => {
  const { itemId } = editor().dataset

  return document.getElementById(itemId)
}
