import { Controller } from '@hotwired/stimulus'
import DevLog from './shared/DevLog'
import GoogleChartsLoader from './loaders/GoogleChartsLoader'

export default class extends Controller {
  initialize () {
    DevLog(['Google Charts loading'])
    GoogleChartsLoader().then(() => {
      DevLog(['Google Charts loaded'])

      import('chartkick')
    })
  }
}
