import { Controller } from '@hotwired/stimulus'
import { ToggleAll } from './shared/Toggle'

export default class extends Controller {
  toggle (e) {
    e.preventDefault()

    // from data-collapse-target='...'
    const className = this.data.get('target')
    const targets = document.getElementsByClassName(className)
    ToggleAll({ targets, collapse: true })
  }
}
