// Generic helper controller to toggle (add or remove) the "hidden" class,
// used for radio buttons.

import { Controller } from '@hotwired/stimulus'
import { TOGGLE_CLASS } from './shared/Toggle'

export default class extends Controller {
  static targets = ['targetA', 'targetB']

  toggleA () {
    this.targetBTarget.classList.add(TOGGLE_CLASS)
    this.targetATarget.classList.remove(TOGGLE_CLASS)
  }

  toggleB () {
    this.targetATarget.classList.add(TOGGLE_CLASS)
    this.targetBTarget.classList.remove(TOGGLE_CLASS)
  }
}
