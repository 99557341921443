import { Controller } from '@hotwired/stimulus'
import DevLog from './shared/DevLog'

export default class extends Controller {
  static values = {
    id: String
  }

  connect () {
    const frameId = this.idValue
    const turboFrame = document.getElementById(frameId)
    DevLog(['Removing frame:', frameId])

    // A little hacky just removing the id, but it works
    turboFrame.setAttribute('id', '')

    // Cleanup
    this.element.remove()
  }
}
