import { Controller } from '@hotwired/stimulus'
import DevLog from './shared/DevLog'
import * as Sentry from '@sentry/browser'

export default class extends Controller {
  static values = {
    id: String
  }

  connect () {
    const frameId = this.idValue
    DevLog(['Reloading frame:', frameId])

    const frame = document.getElementById(frameId)

    if (frame) {
      // Use turbo frame's built in reload() function
      // https://github.com/hotwired/turbo/pull/206
      frame.reload()
    } else {
      DevLog(['Error: frame not found:', frameId])
      Sentry.captureMessage(`Frame with ID ${frameId} not found`, 'error')
    }

    // Cleanup
    this.element.remove()
  }
}
