// Generic helper controller to toggle (add or remove) the "hidden" class,
// used for form selects.

import { Controller } from '@hotwired/stimulus'
import SelectedOption from './shared/SelectedOption'
import ClearInput from './shared/ClearInput'
import { TOGGLE_CLASS } from './shared/Toggle'

export default class extends Controller {
  static targets = ['selector', 'target']

  // Looks for the data attribute "toggle-select" on the selected option
  // and if true it removes the toggle class, ie it shows.
  select () {
    const { toggleSelect } = SelectedOption(this.selectorTarget).dataset
    const el = this.targetTarget

    if (toggleSelect === 'true') {
      el.classList.remove(TOGGLE_CLASS)
    } else {
      el.classList.add(TOGGLE_CLASS)
      // If it has an input field, clear it to '' blank.
      ClearInput(el.querySelector('input'))
    }
  }
}
