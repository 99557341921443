import { Controller } from '@hotwired/stimulus'
import DevLog from './shared/DevLog'

export default class extends Controller {
  static values = {
    src: String
  }

  // Expects response to be in a turbo_frame so we need to find the
  // parentElement of the parentElement to appendChild, move as last node,
  // ie move it below the parentElement.
  connect () {
    DevLog(['Appending frame:', this.element.id])

    // Move below current parentElement
    this.element.parentElement.parentElement.appendChild(this.element)

    // Remove to prevent from re-connecting
    this.element.removeAttribute('data-controller')

    // Set src for Turbo to then fetch
    this.element.setAttribute('src', this.srcValue)

    // Cleanup - not really needed
    this.srcValue = ''
  }
}
