// Webpack 5 dynamic code splitting using dynamic import.
// https://webpack.js.org/guides/code-splitting/

// Singleton pattern with Promise to ensure only called once.
let flatpickrPromise

const FlatpickrLoader = () => {
  if (!flatpickrPromise) {
    flatpickrPromise = import('flatpickr')
  }
  return flatpickrPromise
}

export default FlatpickrLoader
