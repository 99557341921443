import { Controller } from '@hotwired/stimulus'
import { OpenEditor, EditorItem, CloseEditor } from './shared/Editor'

export default class extends Controller {
  open (e) {
    const { editorView } = e.srcElement.dataset
    OpenEditor(this.element, e, editorView)
  }

  close (e) {
    CloseEditor(e)
  }

  replace (e) {
    const el = EditorItem()
    const { url } = this.element.dataset
    const { editorView } = e.srcElement.dataset
    OpenEditor(el, e, editorView, url)
  }
}
