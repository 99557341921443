import { Controller } from '@hotwired/stimulus'
import DevLog from './shared/DevLog'
import FlatpickrLoader from './loaders/FlatpickrLoader'

export default class extends Controller {
  // Initialize all with these CSS classes
  initialize () {
    DevLog(['Flatpickr loading'])
    FlatpickrLoader().then(() => {
      DevLog(['Flatpickr loaded'])
      flatpickrDate('.flatpickr_date')
      flatpickrTime('.flatpickr_time')
    })
  }

  // Find the child element to only clear it
  clearDate (e) {
    e.preventDefault()
    const el = this.element.querySelectorAll('.flatpickr_date')[0]
    flatpickrDate(el).clear()
  }

  clearTime (e) {
    e.preventDefault()
    const el = this.element.querySelectorAll('.flatpickr_time')[0]
    flatpickrTime(el).clear()
  }
}

const flatpickrDate = (el) => {
  return window.flatpickr(el, {
    altInput: true,
    altFormat: 'M j, Y',
    dateFormat: 'm/d/Y'
  })
}

const flatpickrTime = (el) => {
  return window.flatpickr(el, {
    enableTime: true,
    noCalendar: true,
    dateFormat: 'h:i K',
    allowInput: true
  })
}
