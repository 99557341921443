// Generic helper controller to toggle (add or remove) the "hidden" class,
// used for checkboxes.

import { Controller } from '@hotwired/stimulus'
import { ToggleHide, ToggleShow } from './shared/Toggle'

export default class extends Controller {
  static targets = ['content']

  toggle (e) {
    const target = this.contentTarget

    if (e.target.checked) {
      ToggleShow({ target })
      return
    }

    ToggleHide({ target })
  }
}
