// Generic helper controller to toggle (add or remove) the "hidden" class,
// used for menu and popovers.

import { Controller } from '@hotwired/stimulus'
import {
  HasToggleClass,
  ToggleHide,
  ToggleShow,
  TOGGLE_CLASS
} from './shared/Toggle'

// TODO: work on transitions.
// Ref: https://dev.to/mmccall10/tailwind-enter-leave-transition-effects-with-stimulus-js-5hl7
// if (HasToggleClass(menu, TOGGLE_CLASS)) {
//   menu.classList.remove('opacity-0', 'translate-y-1')
//   menu.classList.add('opacity-100', 'translate-y-0')
// } else {
//   menu.classList.remove('opacity-100', 'translate-y-0')
//   menu.classList.add('opacity-0', 'translate-y-1')
// }
export default class extends Controller {
  static targets = ['target']

  // Call hide() for connect to prevent mobile menu from appearing during
  // Turbo preview of cached page. Alternative would be to have:
  // document.addEventListener("turbo:before-cache", function() {...}
  // close the menu before page is cached. This seems simpler and works well.
  // Ref: https://turbo.hotwire.dev/reference/events
  connect (e) {
    this.hide(e)
  }

  toggle (e) {
    e.preventDefault()

    const menu = this.targetTarget
    menu.classList.toggle(TOGGLE_CLASS)

    const trigger = e.currentTarget
    this.setAriaExpanded(trigger, !HasToggleClass(menu, TOGGLE_CLASS))
  }

  hide (e) {
    const target = this.targetTarget
    ToggleHide({ target })

    // When called in from connect(e) above, e is undefined
    if (e && e.currentTarget) {
      const trigger = e.currentTarget
      this.setAriaExpanded(trigger, false)
    }
  }

  show (e) {
    const target = this.targetTarget
    const trigger = e.currentTarget

    ToggleShow({ target })
    this.setAriaExpanded(trigger, true)
  }

  setAriaExpanded (el, bool) {
    if (el.ariaExpanded) {
      el.setAttribute('aria-expanded', bool)
    }
  }

  // Called globally on clicks: click@window->menu-toggle#hideOnOutsideClick
  // to hide if anywhere outside of caller's target is clicked, ie
  // clicking outside the menu will close the menu.
  hideOnOutsideClick (e) {
    const outsideClick = this.element.contains(e.target) === false

    if (outsideClick) {
      this.hide(e)
    }
  }
}
