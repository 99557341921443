import { Controller } from '@hotwired/stimulus'
import CatchError from './shared/CatchError'
import DevLog from './shared/DevLog'
import GoogleAnalyticsLoader from './loaders/GoogleAnalyticsLoader'

export default class extends Controller {
  static values = { id: String }

  connect () {
    DevLog(['Google Analytics loading', this.idValue])

    GoogleAnalyticsLoader()
      .then(() => {
        DevLog(['Google Analytics loaded'])
        window.ga('create', this.idValue, 'auto')
        window.ga('send', 'pageview')
      })
      .catch((error) => {
        CatchError(error)
      })
  }
}
