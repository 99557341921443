import { Controller } from '@hotwired/stimulus'
import SelectedOption from './shared/SelectedOption'

export default class extends Controller {
  static targets = ['sportSelector', 'name', 'court', 'game', 'team']

  // Find selected option from <select> box and use its data- attributes
  // to update all terms on the page.
  select () {
    const { name, courts, games, teams } = SelectedOption(
      this.sportSelectorTarget
    ).dataset

    if (name) {
      this.updateTerm(this.nameTargets, name)
      this.updateTerm(this.courtTargets, courts)
      this.updateTerm(this.gameTargets, games)
      this.updateTerm(this.teamTargets, teams)
    }
  }

  updateTerm (targets, term) {
    targets.forEach((target) => {
      const el = target
      el.innerText = term
    })
  }
}
