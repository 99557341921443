import DynamicScriptLoader from './DynamicScriptLoader'

// Singleton pattern with Promise to ensure only called once.
let intercomPromise

const IntercomLoader = (appId) => {
  if (!intercomPromise) {
    intercomPromise = DynamicScriptLoader({
      url: `https://widget.intercom.io/widget/${appId}`,
      method: 'Intercom',
      name: 'Intercom'
    })
  }
  return intercomPromise
}

export default IntercomLoader
