import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['alert']

  // Autodismiss all but .alert-error after 2.5 seconds
  connect () {
    if (this.autocloseAlert()) {
      setTimeout(() => this.close(), 2500)
    }
  }

  // Close alert when navigating to another page
  disconnect () {
    this.close()
  }

  close () {
    this.alertTarget.remove()
  }

  autocloseAlert () {
    if (this.alertTarget.classList.contains('alert-error')) {
      return false
    }

    return true
  }
}
